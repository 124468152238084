html {
    overflow-x: hidden;
  }
  
  
  button, .button {
    cursor: pointer;
    background-color: #218e33; /* Standardized green */
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 9px;
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: background-color 0.3s ease;
  }
  
  .selected-gallery-update, .gallery-and-new-form-update {
    width: 90%;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff; /* Standardized white */
    border: 1px solid #ccc; /* Standardized border */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }
  
  
  .gallery-and-new-form-update{
 
    max-width: 605px;
    width: 50%; /* or max-width: 500px; */
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;

  }
  
  .form-container-update{
    margin-top: 20px;
  }
  
  h3{
    text-align: center;
  }
  
  .image-details-update {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .image-list-update {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .image-item-update img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .button {
    cursor: pointer;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  select, input[type="text"], input[type="file"], .button {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .gallery-and-new-form-update {
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 20px;
    margin-top: 70px;
    box-sizing: border-box;
    width: 200%;
  }
  
  .gallery-title-update label {
    display: block;
  
  }
  
  .form-container-update {
    display: flex;
    align-items: center;
  }
  
  #gallerySelectUpdate {

    width: 20%; /* or max-width: 500px; */
    margin: 0 auto;

    
  }
  
  
  .add-new-gallery-form-update input[type="text"] {
 
  }
  
  .gallery-nav-update {
    display: flex;
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically if the nav has a specified height */
    gap: 20px;
    padding: 10px 0;
    position: sticky; /* Make the nav stick to the top of the viewport */
    top: 0; /* Position it at the very top */
    z-index: 1000; /* Ensure it stays on top of other content */
    background-color: #ffffff; /* Optional: set a background color to prevent content from showing through */
  }
  
  .gallery-nav-update a {
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .gallery-nav-update a:hover {
    background-color: #e1e1e1;
  }
  
  
  .image-list-update {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Default to 3 columns */
    gap: 20px;
  }
  
  
  .form-group-update{

  }
  
  .form-group-update label {
    display: block;

    color: #6c757d;
  }
  
  .form-group-update input[type="text"],
  .form-group-update textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
    color: #495057;
  }
  
  .form-group-update textarea {
    height: 150px;
    resize: vertical;
  }
  
  button[type="submit"] {
    padding: 10px 20px;
    background-color: #218e33;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }
  
  button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .update-modal-container-update {
   
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  
  .update-modal-update {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 500px;
  }
  
  .close-modal-update {
    float: right;
    margin-top: 30px;
    margin-right: 10px;
    font-size: 10px;
    cursor: pointer;
  }
  
  .image-update-form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff; /* Unified white */
    border: 1px solid #ccc; /* Unified border */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .image-update-form h2 {
    text-align: center;
    color: #333;
  }
  
  .form-group-update {
    margin-bottom: 20px;
  }
  
  .form-group-update label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group-update input[type="text"],
  .form-group-update textarea,
  .form-group-update input[type="file"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .form-group-update textarea {
    height: 100px;
    resize: vertical;
  }
  
  .image-update-form button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #218e33; /* Unified green */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .image-update-form button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .update-image-title{
    margin-left: 55px;

  }

  @media only screen and (min-width: 1400px) {
    .checkbox-position{
      margin-right: 30px;
    }
  }
 
  /* Base styles for all devices */
  /* Add your existing CSS here without modification */
 /* iPhones in portrait & landscape */
@media only screen and (max-width: 660px) {
  .gallery-container-update, .image-upload-form, .selected-gallery-update, .gallery-and-new-form-update {
    flex-direction: column;
    align-items: center; /* Ensures content within these containers is centered */
    width: 90%;
    margin: 20px auto;
    padding: 10px;
    box-sizing: border-box; /* This helps maintain width rules including padding */
  }

  .gallery-nav-update {
    flex-direction: column;
    width: 100%; /* Full width */
    padding: 10px 0; /* Top and bottom padding */
  }

  .gallery-nav-update a {
    display: block; /* Ensures full-width clickable area */
    margin: 5px 0; /* Space between items */
    width: 100%; /* Full width */
    text-align: center; /* Center text */
  }

  .form-container-update, .image-details-update {
    flex-direction: column;
    align-items: center; /* Center-align content within these divs */
    width: 100%;
  }

  .image-list-update {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Single column layout */
    width: 100%; /* Full width */
    justify-items: center; /* Center grid items */
  }

  .selected-gallery-update, .gallery-and-new-form-update {
    width: 90%; /* Adjust width to be consistent */
    margin: 20px auto; /* Centering */
  }

  h2, h3, .button, select, input[type="text"], input[type="file"] {
    font-size: 14px;
    text-align: center; /* Center-align text for better readability */
  }

  .image-item-update img {
    width: 100%;
    height: auto;
  }
}

  
  /* Specific adjustments for various iPhone sizes might go here if necessary, 
     targeting widths like 375px for iPhone 6/7/8 and 414px for iPhone 6+/7+/8+,
     although the above should suffice for a broad compatibility. */
  


/* Media query for mini tablets */
/* Media query for mini tablets */
@media only screen and (min-width: 750px) and (max-width: 900px) {
  .gallery-container-update, .image-upload-form, .selected-gallery-update, .gallery-and-new-form-update {
    flex-direction: column;
    align-items: center; /* Ensure items are centered */
    width: 90%;
    margin: 20px auto; /* Center the container */
    padding: 10px;
  }

  .gallery-nav-update {
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
  }

  .form-container-update, .image-details-update {
    flex-direction: column;
    width: 100%;
  }

  .image-list-update {
    grid-template-columns: repeat(2, 1fr); /* Two columns for better use of space */
  }
}
