html {
  overflow-x: hidden;
}

button, .button { /* This selector targets both <button> elements and elements with the 'button' class */
  cursor: pointer;
  background-color: #218e33; /* Primary button color */
  color: #ffffff; /* Text color */
  border: none;
  padding: 10px 20px; /* Adjust padding to fit your design */
  border-radius: 5px; /* Rounded corners */
  font-size: 9px; /* Text size */
  text-transform: uppercase; /* UPPERCASE text */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Keeps the two columns layout */
  gap: 20px; /* Adjust the gap between images */
  padding: 20px;
}

.title-gallery-manager{
  margin-top: 20px;
  margin-left: 30px;
}


.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 20px;
  max-width: 1200px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
   width: 100%; 
}
h2 {
  position: absolute;
  top: 10%;
  left: calc(50% - 125px); /* Adjust as needed based on the width of the gallery container */
  transform: translateY(-50%);
}
.gallery-header {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 100%; 
}

.gallery-selection, .new-gallery-form, .selected-gallery {
  width: 100%; 
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.gallery-and-new-form{
  margin-top: 50px;
  max-width: 605px;

  margin-left: 300px; /* Adjust this value to move it more to the right */
  text-align: center;
  width: 100%; 
}

.form-container{
  margin-left: 80px;
}

h3{
  text-align: center;
}

.image-upload-form {
  position: absolute;
  top: 65%;
  left: calc(50% - 300px); /* Adjust as needed based on the width of the gallery container */
  transform: translateY(-50%);
  width: calc(100% - 800px); /* Adjust as needed based on the width of the gallery container */
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.selected-gallery{
  margin-top: 420px;
  width:190%;
  max-width: 1000px;
  align-items: center;
  margin-left: 75px;
}

.image-entry, .form-actions, .image-list .image-item {
  margin-bottom: 20px;
}

.image-entry input[type="text"], .image-entry input[type="file"] {
  width: 95%;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}

.form-actions, .image-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-item img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.button {
  cursor: pointer;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

select, input[type="text"], input[type="file"], .button {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.gallery-and-new-form {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  width: 200%;
}

.gallery-title label {
  display: block;
  margin-bottom: 20px;
}

.form-container {
  display: flex;
  align-items: center;
}

#gallerySelect {
  margin-right: 20px;
}

.add-new-gallery-form input[type="text"] {
  margin-right: 10px;
}

.gallery-nav {
  display: flex;
  justify-content: center; /* Centers items horizontally */
  align-items: center; /* Centers items vertically if the nav has a specified height */
  gap: 20px;
  padding: 10px 0;
  position: sticky; /* Make the nav stick to the top of the viewport */
  top: 0; /* Position it at the very top */
  z-index: 1000; /* Ensure it stays on top of other content */
  background-color: #fff; /* Optional: set a background color to prevent content from showing through */
}

.gallery-nav a {
  color: #000000;
  cursor: pointer;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.gallery-nav a:hover {
  background-color: #e1e1e1;
}


.image-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default to 3 columns */
  gap: 20px;
}


.about-update-section {
  margin-top: 50px;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.about-update-section h2 {
  margin-bottom: 20px;
  color: #495057;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #6c757d;
}

.form-group input[type="text"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  color: #495057;
}

.form-group textarea {
  height: 150px;
  resize: vertical;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: #218e33;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.update-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.update-modal {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
}

.close-modal {
  float: right;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}



/* Base styles for all devices */
/* Add your existing CSS here without modification */

/* iPhones in portrait & landscape */
/* @media only screen and (max-width: 428px) {
  .gallery-container, .image-upload-form, .selected-gallery, .gallery-and-new-form {
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 20px auto;
    padding: 10px;
  }

  .gallery-nav {
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
  }

  .gallery-nav a {
    margin: 5px 0; 
  }

  .form-container, .image-details, .form-actions {
    flex-direction: column;
    width: 100%;
  }

  .image-list {
    grid-template-columns: repeat(1, 1fr); 
  }

  .selected-gallery, .image-upload-form {
    margin-top: 0;
    margin-left: 0;
  }

  h2, h3, .button, select, input[type="text"], input[type="file"] {
    font-size: 14px; 
  }

  .image-item img {
    width: 100%; 
    height: auto;
  }

  .gallery-and-new-form, .selected-gallery {
    max-width: none;
  }
} */




.hamburger-menu {
  display: none; /* Hide hamburger menu on larger screens */
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px;
  font-size: 30px;
  cursor: pointer;
  z-index: 1001; /* Ensure it is above other content */
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 10px; /* Adjust padding as necessary */
}

.back-button, .logout-button {
  padding: 8px 16px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button{
  margin-right: 10px;
}

/* Media query for iPhones and smaller devices */
@media only screen and  (min-width: 600px) and (max-width: 1100px) {

  .back-button, .logout-button {
    margin-right: 15px; /* Adjusted for visual fit */
    margin-top: 10%;
    white-space: nowrap;
  }
  .button-container{
    margin-top: 10%;
  }
  .hamburger-menu {
    display: block;
    position: fixed; /* Ensures menu is accessible from any scroll position */
    top: 10px;
    left: 10px;
    z-index: 1200; /* Higher than the nav to ensure it's clickable */
    font-size: 30px;
    cursor: pointer;
  }

  .gallery-nav {
    position: fixed;
    top: 0; /* Align top to the top of the viewport */
    left: 0;
    width: 250px; /* Width of the sidebar */
    height: 100%; /* Full height of the viewport */
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    transform: translateX(-100%); /* Start hidden to the left */
    transition: transform 0.3s ease;
    display: block;
    padding-top: 50px; /* Space for the hamburger menu */
    z-index: 1100; /* Ensure it overlays content */
  }

  .gallery-nav.open {
    transform: translateX(0); /* Slide in */
  }

  .gallery-nav a {
    display: block; /* Make each link full-width */
    padding: 10px 20px; /* Padding for touch targets */
    text-decoration: none; /* No underlines */
    color: #333; /* Default text color */
    border-bottom: 1px solid #eaeaea; /* Separator between items */
    background: #fff; /* Background color */
    transition: background-color 0.3s ease;
  }

  .section-addImageEntry {
    margin-top: 125px;
  }
  
  .section-addImages {
    margin-top: 150px;
  }
  
  .section-updateAboutPage {
    margin-top: 125px;
  }
  
  .section-updateGallery {
  
    margin-top: 150px; 
  }

  .logout-button {
    margin-right: 15px; /* Adjusted for visual fit */
    margin-top: 10%;
    white-space: nowrap;
  }
  .title-gallery-manager{
    display: block;
    text-align: center;
    margin-top: 70%;
    margin-left: 4vh;
  }
}


/* Media query for iPhones and smaller devices */
@media only screen and (max-width: 600px) {

  .back-button, .logout-button {
    margin-right: 15px; /* Adjusted for visual fit */
    margin-top: 10%;
    white-space: nowrap;
  }
  .button-container{
    margin-top: 10%;
  }
  .hamburger-menu {
    display: block;
    position: fixed; /* Ensures menu is accessible from any scroll position */
    top: 10px;
    left: 10px;
    z-index: 1200; /* Higher than the nav to ensure it's clickable */
    font-size: 30px;
    cursor: pointer;
  }

  .gallery-nav {
    position: fixed;
    top: 0; /* Align top to the top of the viewport */
    left: 0;
    width: 250px; /* Width of the sidebar */
    height: 100%; /* Full height of the viewport */
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    transform: translateX(-100%); /* Start hidden to the left */
    transition: transform 0.3s ease;
    display: block;
    padding-top: 50px; /* Space for the hamburger menu */
    z-index: 1100; /* Ensure it overlays content */
  }

  .gallery-nav.open {
    transform: translateX(0); /* Slide in */
  }

  .gallery-nav a {
    display: block; /* Make each link full-width */
    padding: 10px 20px; /* Padding for touch targets */
    text-decoration: none; /* No underlines */
    color: #333; /* Default text color */
    border-bottom: 1px solid #eaeaea; /* Separator between items */
    background: #fff; /* Background color */
    transition: background-color 0.3s ease;
  }

  .section-addImageEntry {
    margin-top: 125px;
  }
  
  .section-addImages {
    margin-top: 150px;
  }
  
  .section-updateAboutPage {
    margin-top: 125px;
  }
  
  .section-updateGallery {
  
    margin-top: 150px; 
  }

  .logout-button {
    margin-right: 15px; /* Adjusted for visual fit */
    margin-top: 10%;
    white-space: nowrap;
  }
  .title-gallery-manager{
    display: block;
    text-align: center;
    margin-top: 70%;
    margin-left: 7vh;
  }
}






.title-gallery-manager{
  display: block;
  text-align: center;
}

/*iPad Vertical*/
@media screen and (min-width: 700px) and (max-width: 1100px) and (min-height: 1000px) and (max-height: 1400px) {
  .back-button, .logout-button {
    margin-right: 5vh; /* Adjusted for visual fit */
    margin-top: 10%;
    white-space: nowrap;
  }

  .logout-button{
    margin-left: 460px;
  }

  .title-gallery-manager{
    display: block;
    text-align: center;
  }
}

/*iPad Mini Horizontals*/
@media screen and (min-width: 1000px) and (max-width: 1099px) and (min-height: 700px) and (max-height: 1100px) {
  .back-button, .logout-button {
    margin-right: 100px; /* Adjusted for visual fit */
    margin-top: 10%;
    white-space: nowrap;
  }
  .title-gallery-manager{
    display: block;
    text-align: center;
  }
}

/*Regular iPhone*/
@media screen and (min-width: 350) and (max-width: 450) and (min-height: 850) and (max-height: 950) {
  .back-button, .logout-button {
    margin-right: 3vh; /* Adjusted for visual fit */
    margin-top: 10%;
    white-space: nowrap;
  }
  .button-container{
    margin-top: 20px;
  }
  .title-gallery-manager{
    margin-top: 200px !important; 
  }

}


/*iPad horizontal*/
@media screen and (min-width: 1024px) and (max-width: 1400px) and (min-height: 700px) and (max-height: 1100px) {
  .back-button, .logout-button {
    margin-right: 3vh; /* Adjusted for visual fit */
    white-space: nowrap;
  }
  .title-gallery-manager{
    display: block;
    text-align: center;
  }
}




