.gallery-container-cn {
    position: fixed;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    padding: 20px;
    background-color: #ffffff; /* Unified white */
    border: 1px solid #ccc; /* Unified border */
    border-radius: 8px;
  }
  
  .gallery-and-new-form-cn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gallery-title-cn {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .form-container-cn {
    width: 100%;
  }
  
  .add-new-gallery-form-cn {
    display: flex;
    flex-direction: column;
  }
  
  .add-new-gallery-form-cn input[type="text"] {
    width: calc(100% - 20px); /* Account for padding */
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc; /* Unified border */
    border-radius: 4px;
    margin-bottom: 10px;
  }
  
  .add-new-gallery-form-cn button[type="submit"] {
    width: 100%;
    padding: 8px 16px;
    background-color: #218e33; /* Unified green */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-new-gallery-form-cn button[type="submit"]:hover {
    background-color: #0056b3; /* Adjusted for consistency */
  }
  
  .login-message {
    font-size: 18px;
    font-weight: bold;
    color: #ff4d4d; /* Unified red */
  }
  
  @media (max-width: 768px) {
    .gallery-container-cn {
      width: 80%; /* Makes the container width responsive */
      top: 30%; /* Adjust positioning to be more visually appealing on smaller devices */
      padding: 15px; /* Slightly less padding to allow more content to fit */
    }
  }
  
  @media (max-width: 1400px) {
    .gallery-container-cn {

      top: 25%; /* Adjust positioning to be more visually appealing on smaller devices */
      padding: 15px; /* Slightly less padding to allow more content to fit */
    }
  }
  @media (max-width: 1200px) {
    .gallery-container-cn {

      top: 35%; /* Adjust positioning to be more visually appealing on smaller devices */
      padding: 15px; /* Slightly less padding to allow more content to fit */
    }
  }

  @media (min-width: 1000px) and (max-width: 1100px) {
    .gallery-container-cn {

      top: 65%; /* Adjust positioning to be more visually appealing on smaller devices */
      padding: 15px; /* Slightly less padding to allow more content to fit */
    }
  }

  /* Adjustments for mobile devices */
  @media (max-width: 480px) {
    .gallery-container-cn {
      width: 95%; /* Increases width to utilize more screen space */
      top: 40%; /* Higher up to leave space for potentially virtual keyboards */
      padding: 10px; /* Reduces padding to maximize use of space */
    }
  }