.about-update-section-ab {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  background-color: #ffffff; /* Unified white background */
}

.about-update-section-ab form {
  width: 100%;
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  background-color: #ffffff; /* Unified white */
  border: 1px solid #ccc; /* Unified border */
  margin: 0 auto; /* Center the form horizontally with automatic margins */
}

.form-group-about {
  margin-bottom: 20px;
}

.form-group-about label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group-about input[type="text"],
.form-group-about textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc; /* Unified border */
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group-about textarea {
  height: 150px;
  resize: vertical;
}

.about-update-section-ab button[type="submit"] {
  background-color: #218e33; /* Unified green */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  width: 100%;
}

.about-update-section-ab button[type="submit"]:hover {
  background-color: #0056b3; /* Adjusted for consistency */
}
