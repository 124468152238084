
.about .background-image-container {
  position: relative;
  width: 100%;
}

.about .background-image-container .background-image {
  width: 100%;
  height: auto; /* Adjust height as needed, or set to a specific value */
  display: block;
}



/* About.css */
.contact-container {
  display: flex;      /* Enables flexbox layout */
  align-items: center; /* Centers the items vertically */
  justify-content: space-between; /* Distributes space between and around content items */
}

.contact-box {
  width: 50%;       /* Adjust width as necessary */  /* Keeps existing styling */
}

.contact-message {
  width: 50%;       /* Adjust width as necessary */
  padding: 20px;    /* Adds padding for spacing, adjust as needed */
  text-align: center; /* Optional: centers the text inside the message box */
  font-family: Arial, sans-serif; /* Change the font family */
  font-size: 36px; /* Increase the font size */
  color: #133f4c; /* Change the text color */
}


/* Adjustments for the smaller text box */
.about .background-image-container .text-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 15px; /* Reduced padding */
  border-radius: 8px;
  width: 60%; /* Make the box narrower */
  max-width: 500px; /* Adjust max width for smaller size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: justify;
  overflow: hidden; /* Ensures the content does not overflow the box */
}

/* Adjustments for the title to fit within the smaller text box */
.about .background-image-container .text-box .title {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1.5em; /* Smaller font size for the title */
  text-align: center;
  color: #333;
  margin-bottom: 15px; /* Adjusted spacing */
}

/* Adjustments for the paragraph text to fit within the smaller text box */
.about .background-image-container .text-box p {
  font-family: 'Roboto', sans-serif; /* Assuming you want to keep the font consistent */
  font-size: 0.9em; /* Smaller font size for the text */
  margin-bottom: 10px; /* Adjusted spacing between paragraphs */
}



/* Assuming you've chosen a Google Font like Roboto for its professional appearance */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.about .background-image-container .text-box .title {
  font-family: 'Roboto', sans-serif; /* Use the imported Google Font */
  font-weight: 700; /* Adjust weight as needed */
  font-size: 2em; /* Adjust size as needed */
  text-align: center; /* Center the title */
  color: #333; /* Adjust color as needed */
  margin-bottom: 20px; /* Adds some space between the title and the text */
}

.aboutText {
  background: rgba(255, 255, 255, 0.85); /* Semi-transparent white background */
  width: 80%; /* Set width to prevent text from being too wide */
  margin: 0 auto; /* Center the block */
  max-width: 800px; /* Maximum width can be adjusted based on design preference */
  font-family: 'Open Sans', sans-serif; /* Assuming you want a sans-serif font */
  line-height: 1.6; /* Adjust line height for readability */
  text-align: justify; /* Justify text for clean edges */
  text-indent: 20px; /* Indent the first line of paragraphs */
  color: #333; /* Dark grey color for text */
  margin-top: 20px;


  height: 150px; /* Set an appropriate fixed height */
}

.aboutFeaturedTitle, .contact-ck {
  font-family: 'Open Sans', sans-serif; /* You can choose a different font if you like */
  font-size: 1.5em; /* Adjust font size as needed for it to appear larger */
  color: #333; /* Dark grey color for text, can be adjusted as needed */
  text-align: center; /* Centers the text */
  margin-top: 95px; /* Adds space above the title, adjust as needed */
  margin-bottom: 20px; /* Adds space below the title, adjust as needed */
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: calc(100% - 40px); /* Subtract total horizontal padding from width */
  margin: 20px auto;
  padding: 0 20px; /* Adds padding on both sides */
  box-sizing: border-box;
}


.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* You can choose 'cover', 'contain', or other values based on your preference */
}

.admin-login {
  color: rgb(75, 75, 75) !important;
}


.footer-content {
  text-align: center;
  margin-top: 20px;
  padding: 10px 0;
  color: #000000;
}

.footer-content a {
  color: #0000EE; /* Standard link color */
  text-decoration: none; /* Optional: removes underline from links */
}

.footer-content a:hover {
  text-decoration: underline; /* Re-add underline on hover for better UX */
}


/* Responsive adjustments */
@media (max-width: 1024px) { /* Medium screens and down */
  .image-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 images per row */
  }
}

@media (max-width: 600px) { /* Small screens and down */
  .image-grid {
    grid-template-columns: 1fr; /* 1 image per row */
  }
}


/* Responsive adjustments for iPad (tablets) */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column; /* Stack vertically instead of horizontally */
  }

  .contact-box, .contact-message {
    width: 100%; /* Each takes full width of the container */
    margin-bottom: 20px; /* Adds space between the stacked items */
  }
}
