/* Base styles for the contact section */
.contact-section {
    max-width: 600px;
    margin: 60px auto;
    padding: 30px;
    background: #ffffff; /* Switched to a plain white background for simplicity and professionalism */
    font-family: 'Arial', sans-serif; /* Ensure text readability */
}

/* Style adjustments for the form and its elements */
.contact-section form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-section input,
.contact-section textarea {
    padding: 15px;
    border: 2px solid #e2e2e2; /* Lighter border for a subtle look */
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box; /* Ensures padding does not affect width */
    transition: border-color 0.3s;
}

.contact-section input:focus,
.contact-section textarea:focus {
    border-color: #0056b3; /* Highlight focus with a deeper color */
    outline: none;
}

.contact-section textarea {
    height: 120px; /* Optimized height */
    resize: none; /* Disable resizing to maintain form structure */
}

.contact-section button {
    padding: 10px 20px;
    background-color: #0056b3; /* Consistent theme color */
    border: none;
    border-radius: 8px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.contact-section button:hover {
    background-color: #003d82; /* Darken button on hover for better interaction feedback */
    transform: translateY(-2px); /* Slight lift effect */
}

/* Responsive Design Tweaks */
@media (max-width: 768px) {
    .contact-section {
        padding: 20px;
        margin: 40px 20px;
    }

    .contact-section input,
    .contact-section textarea,
    .contact-section button {
        font-size: 16px; /* Increase font size for better readability on smaller devices */
    }
}

@media (max-width: 480px) {
    .contact-section {
        margin: 20px;
        padding: 15px;
    }

    .contact-section button {
        width: 100%; /* Full width button on very small screens */
    }
}
